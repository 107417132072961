import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import * as Icon from "../../Components/Icon";
import Link from "../../Components/Link";
import FeatureSection from "../../Components/FeatureSection";
import Carousel from "nuka-carousel";
import * as Widget2 from "../../Components/Widget2";
import Login from "../../Components/Login";

class HomePage extends React.Component {
  render() {
    return (
      <Wrapper>
        <Carousel
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
          autoplay={false}
          autoplayInterval={3000}
        >
          <Style.CarouselItem>
            <Widget2.Figure
              image={"http://placehold.it/1000x400/888888/c0392b/&text=slide1"}
            />
          </Style.CarouselItem>
          <Style.CarouselItem>
            <Widget2.Figure
              image={"http://placehold.it/1000x400/888888/c0392b/&text=slide2"}
            />
          </Style.CarouselItem>
          <Style.CarouselItem>
            <Widget2.Figure
              image={"http://placehold.it/1000x400/888888/c0392b/&text=slide3"}
            />
          </Style.CarouselItem>
        </Carousel>

        {this._renderStartSection()}

        <Widget2.Row justify="center" align="center" css="padding: 15px;">
          <Widget2.Spinner css="margin-right: 15px" />
          <h2>Keep Going!</h2>
          <Login.Button />
          <Widget2.FlatButton>Learn More</Widget2.FlatButton>
        </Widget2.Row>

        <FeatureSection items={FeatureItems} />

        {this._renderAboutSection()}
      </Wrapper>
    );
  }

  _renderStartSection = () => (
    <TextSection>
      <h1>Our Products</h1>
      <p>
        {
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. \
Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus \
et magnis dis parturient montes, nascetur ridiculus mus. "
        }
      </p>
      <Link
        to="/products"
        extraStyle={{ marginTop: 20, textDecoration: "none" }}
      >
        <Tel25ButtonWrapper>
          <Icon.Cart
            color="#0079ff"
            style={{ marginRight: 10, width: 30, height: 30 }}
          />
          <div>Buy Now</div>
        </Tel25ButtonWrapper>
      </Link>
    </TextSection>
  );

  _renderAboutSection = () => (
    <TextSection bgColor="#ccc" textColor="white">
      <h1>About</h1>
      <p>
        {
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. \
Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus \
et magnis dis parturient montes, nascetur ridiculus mus. "
        }
      </p>
    </TextSection>
  );
}

const FeatureItems = [
  {
    title: "Sun",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    icon: Icon.WbSunny
  },
  {
    title: "Cloud",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    icon: Icon.CloudDone
  },
  {
    title: "People",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    icon: Icon.SupervisorAccount
  },
  {
    title: "Tag",
    text: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit.",
    icon: Icon.Loyalty
  }
];

const Wrapper = styled.div``;

const Tel25ButtonWrapper = styled.div`
  border-radius: 30px;
  min-width: 200px;
  border: 2px solid #0079ff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;

  & > div {
    padding: 10px;
    padding-right: 0px;
    font-size: 24px;
    border-left: 1px solid #0079ff;
    color: #0079ff;
  }
`;

const TextSection = styled.div`
  padding: 65px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${props => props.bgColor || "white"};
  color: ${props => props.textColor || "black"};

  & > h1 {
    margin-bottom: 20px;
    color: ${props => props.textColor || "black"};
  }

  & > p {
    white-space: pre-wrap;
    max-width: 800px;
    color: ${props => props.textColor || "black"};
  }

  ${props => props.extraCss || ""};
`;

const Style = {
  CarouselItem: styled.div`
    width: 100%;
    height: 600px;
    position: relative;

    & > figure {
      width: 100%;
      height: 100%;
    }

    @media screen and (max-width: 600px) {
      height: 400px;
    }
  `
};

export default withPage(HomePage);
